<template>
  <Layout>
        <b-button @click="openModal"> Add Banner </b-button>
    <div class="row mt-2">
        <div class="col-12">
        <div class="card">
            <div class="card-body">
            <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                    </label>
                </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                >
                    <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                    </label>
                </div>
                </div>
                <!-- End search -->
            </div>
            <div class="text-center text-danger my-2" v-if="isBusy">
              <b-spinner class="align-middle"></b-spinner>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" v-else>
                <b-table
                :items="bannerData"
                :fields="fields"
                responsive="sm"
                :busy="isBusy"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    </div>
                </template>

                <template v-slot:cell(banner_image)="row">
                        <b-img
                            thumbnail
                            class="banner-image"
                            :src="row.value"
                            width="50"
                        ></b-img>
                        </template>
                         <template v-slot:cell(plans)="row">
                           <div class="row">
                           <div v-for="(item, i) in row.item.plans" :title="item" :key="i">
                         <div class="col-12"> 
                           <a style="cursor: none;" disabled href="#" class="btn btn-primary">{{item.name}}</a>
                         </div>
                          </div>
                                                     </div>

                       
                        </template>
                <template #cell(actions)="row">
                    <button class="action-btn" @click="toggleBanner(row.item)">
                    <i class="fas fa-trash-alt"></i>
                    </button>
                    <button class="action-btn" @click="editBanner(row.item)">
                    <i class="fas fa-edit"></i>
                    </button>
                </template>
                </b-table>
            </div>
            <div class="row">
                <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                    <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                    ></b-pagination>
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
            <!-- <Modal /> -->

    </div>
    <BannerModal
    :selectedBanner="selectedBanner"
      v-on:resetModal="selectedBanner = null"
      v-on:reloadData="getAllBanners"
      v-on:closeModal="hideActionModal"
      :selectCampaignOptions="selectCampaignOptions"
      />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import {company_banner} from "@/config/api/company_banner";
import {plans} from "@/config/api/plans";
import BannerModal from "@/components/company_banner/action-modal";

export default {
  components: {
    Layout,
    BannerModal
    // Modal
  },
  data() {
    return {
      bannerData: [],
      selectedBanner: null,
      selectCampaignOptions: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      fields: [
        { key: "banner_image", sortable: false },
        { key: "plans", sortable: false },
        // { key: "isActive", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
  },
  created(){
    this.getAllBanners()
  },
  methods: {
    editBanner(data) {
      this.selectedBanner = data;
            this.$bvModal.show("banner-modal");

    },
      openModal() {
      this.$bvModal.show("banner-modal");
    },
    hideActionModal() {
      this.$bvModal.hide("banner-modal");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleBanner(data){
      const api = company_banner.delete;
      api.id = data._id;
      this.generateAPI(api).then(() => {
          this.$bvToast.toast("Banner deleted successfully!", {
            title: "Banner Delete",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast("Banner was not deleted!", {
            title: "Banner Delete",
            variant: "danger",
            solid: true,
          });
          console.log(err.data);
        }).finally(() => {
          this.getAllBanners()
        })
    },
    async getAllBanners() {
        this.isBusy = true;
        const api = company_banner.get;
        let res = await this.generateAPI(api);
        this.bannerData = res.data.banners;

        try {
        const api = plans.get;
        this.generateAPI(api).then((res) => {
          this.selectCampaignOptions = res.data.plan;
        });
        
      } catch (error) {
        console.error(error);
      }
      this.isBusy = false;
    },
  },
};
</script>

<style></style>
